import {
    Box,
    Button, Divider, Grid, IconButton,
    Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField
} from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import QuickviewModal from 'components/QuickviewModal';
import React, { useState } from 'react';
import getBase64 from 'utils/getBase64';
import OEOutputType from './OEOutputType';
import PrintIcon from '@material-ui/icons/Print';

export default function P2Items({
    code, itemType, items, addProductToCart, user, setFile, downloadRoot, id, productDetail, outputType, onOutputTypeChange,
    showQty, quantity, quantityOnChange, quantityOnBlur, showPricing, openPricing, addToCart, disableAddToCart, hidePricing, goBack
}) {
    const [fileData, setFileData] = useState('');
    const [fileName, setFileName] = useState('');
    const addItemToCart = (item) => {
        //Change item qty to 1 when adding kit components per ODSAETNA-1661
        item.qty = 1;

        addProductToCart({
            ...item, customFields: item, fileData, fileName
        }, false);
    };

    const [uploading, setUploading] = useState(false);

    const [openQuickView, setOpenQuickView] = useState(false);
    const [quickViewPdf, setQuickViewPdf] = useState(null);
    const [quickViewItem, setQuickViewItem] = useState(null);
    const [printPdf, setPrintPdf] = useState(null);
    const showQuickViewItem = (j, printPdf = false) => {
        let pdfURL = `${downloadRoot}/${j.code}/${j.id}?Authorization=${user.token}`;
        pdfURL = printPdf ? pdfURL.replace('-img', '-view').replace('-dl', '-view')  : pdfURL.replace('-dl', '-img');

        setQuickViewItem(j);
        setPrintPdf(printPdf);
        setQuickViewPdf(pdfURL);
        setOpenQuickView(true);
    };

    const startUpload = (e) => {
        if (!e.target.files.length) return;
        const file = e.target.files[0];
        const uploadFileName = file.name;
        getBase64(file).then(async (fd) => {
            setFileData(fd);
            setFileName(uploadFileName);
            setFile(uploadFileName, fd);
        });
    };

    let itemDownloadLink = itemType === 'KIT'
        ? `${downloadRoot}/${id}?Authorization=${user.token}`
        : `${downloadRoot}/${itemType}/${id}?Authorization=${user.token}`;

    let downloadEnabled = true;

    if(itemType === 'KIT' && productDetail && productDetail.customFields) {
        downloadEnabled = productDetail.customFields.kitDownloadEnabled;
    }

    if(itemType !== 'KIT' && productDetail && productDetail.customFields) {
        downloadEnabled = productDetail.customFields.itemDownloadEnabled;
    }

    const externalLinks = user && user.customFields && user.customFields.p2ExternalKitComponents;

    let itemLink = externalLinks && externalLinks.find(x => x.itemNumber === code);
    if(itemLink != null) {
        itemDownloadLink = itemLink.externalLink; 
    }

    return (
        <>
            <Box mb={2}>
                <b>Item Number</b>: {code}
                {downloadEnabled ? <Link href={itemDownloadLink} target="_blank" style={{ marginLeft: '1rem' }} color="primary">
                    <CloudDownloadIcon />
                </Link> : null}
            </Box>
            <Box mb={2}>
                <b>Item Type</b>: {itemType}
            </Box>
            <Grid
                container
                spacing={4}
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={6}>
                    {productDetail.program === 'OE' && (
                        <OEOutputType
                            outputType={outputType}
                            onOutputTypeChange={onOutputTypeChange}
                            disableDigital={productDetail.customFields.disableDigital}
                            disablePrint={productDetail.customFields.disablePrint}
                        />
                    )}
                    {showQty && productDetail.customFields.orderable === null || productDetail.customFields.orderable && (
                        <TextField
                            id="outlined-qty"
                            label={itemType === 'KIT' ? "Kit Qty" : "Item Qty"}
                            size="small"
                            type="number"
                            min={productDetail.minQty || 1}
                            style={{ width: '6rem' }}
                            value={quantity}
                            onChange={quantityOnChange}
                            onBlur={quantityOnBlur}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                        />
                    )}
                </Grid>

                <Grid item xs={6}>
                    <Button
                        type="button"
                        size="large"
                        variant="outlined"
                        color="primary"
                        onClick={goBack}
                    >
                        <ArrowBackIosIcon /> Back
                    </Button>

                    {
                        productDetail.customFields.orderable === null || productDetail.customFields.orderable && (
                            <Button
                                type="button"
                                size="large"
                                variant="contained"
                                color="primary"
                                onClick={addToCart}
                                disabled={disableAddToCart}
                                style={{ float: 'right' }}
                            >
                                ADD TO CART
                            </Button>
                        )
                    }
                </Grid>
            </Grid>
            <></>
            {/* {isP2Admin && (
                <Box mb={2}>
                    <b>Attach PDF</b>:
                    <IconButton size="small" color="primary">
                        <label htmlFor="static-pdf-upload" style={{ cursor: 'pointer' }}>
                            {uploading
                                ? <CircularProgress color="secondary" size={12} />
                                : <UploadIcon />}
                            <Input
                                type="file"
                                inputProps={{ id: 'static-pdf-upload', accept: '.pdf' }}
                                style={{ display: 'none' }}
                                onChange={startUpload}
                            />
                        </label>
                    </IconButton>
                    <i>{fileName}</i>
                </Box>
            )} */}
            <Divider color="" style={{ height: '.1rem', marginTop: '1rem', marginBottom: '1rem' }} />
            {
                items && (
                    <Box mb={2}>
                        <b>Kit Components</b>:
                        <TableContainer>
                            <Table size="small" aria-label="components table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold', padding: '6px 12px 6px 8px' }}>Item Number</TableCell>
                                        <TableCell style={{ fontWeight: 'bold', padding: '6px 12px 6px 8px', maxWidth: '200px' }}>Description</TableCell>
                                        <TableCell style={{ fontWeight: 'bold', padding: '6px 12px 6px 8px' }}>Version</TableCell>
                                        <TableCell style={{ width: '3rem' }} />
                                        <TableCell style={{ width: '3rem' }} />
                                        <TableCell style={{ width: '3rem' }} />
                                        <TableCell style={{ width: '3rem' }} />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((j) => {
                                        const extLink = externalLinks && externalLinks.length > 0 && externalLinks.find(l => l.itemNumber === j.code);
                                        const link = extLink && extLink.externalLink || `${downloadRoot}/${j.code}/${j.id}?Authorization=${user.token}`;

                                        return <TableRow key={j.id}>
                                            <TableCell style={{ padding: '6px 12px 6px 8px' }}>{j.code}</TableCell>
                                            <TableCell style={{ padding: '6px 12px 6px 8px', maxWidth: '200px', overflowWrap: 'anywhere' }}>{j.name}</TableCell>
                                            <TableCell style={{ padding: '6px 12px 6px 8px' }}>{j.version}</TableCell>
                                            <TableCell style={{ padding: '6px' }}>
                                                {j.orderable === 'YES' && (
                                                    <IconButton color="primary" size="small" onClick={() => addItemToCart(j)}>
                                                        <AddShoppingCartIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell style={{ padding: '6px' }}>
                                                {j.downloadable === 'YES' && (
                                                    <Link
                                                        href={link}
                                                        target="_blank"
                                                        style={{ marginLeft: '1rem' }}
                                                        color="primary"
                                                    >
                                                        <CloudDownloadIcon />
                                                    </Link>
                                                )}
                                            </TableCell>
                                            <TableCell style={{ padding: '6px' }}>
                                                {j.downloadable === 'YES' && (
                                                    <IconButton color="primary">
                                                        <PrintIcon onClick={() => showQuickViewItem(j, true)}/>
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell style={{ padding: '6px' }}>
                                                {extLink ? null :
                                                    <IconButton color="primary" size="small" onClick={() => showQuickViewItem(j)}>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )
            }

            {
                openQuickView && (
                    <QuickviewModal
                        open={openQuickView}
                        onCancel={() => { setOpenQuickView(false); setQuickViewPdf(null); }}
                        pdf={quickViewPdf}
                        item={quickViewItem}
                        printPdfOnLoad={printPdf}
                    />
                )
            }
            <Divider color="" style={{ height: '.2rem', marginTop: '1rem', marginBottom: '1rem' }} />

        </>
    );
}
